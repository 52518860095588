<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>
            الاجهزة
            <button
              class="btn btn-sm btn-relief-primary"
              @click="$router.push('/devices/add')"
            >
              <i class="fa fa-plus"></i> إضافة جهاز
            </button>
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>الاسم</th>
                <th>السعر</th>
                <th>متاح</th>
                <th>خيارات</th>
              </thead>
              <tbody>
                <tr v-for="place in devices" :key="place._id">
                  <td>
                    <img :src="place.image" style="width: 30px; height: 30px" />
                    {{ place.title }}
                  </td>
                  <td>
                    {{ place.price }}
                  </td>
                  <td>
                    {{ place.available ? "نعم" : "لا" }}
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-success"
                      @click="$router.push('/devices/edit/' + place._id)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-outline-danger"
                      style="margin-right: 3px"
                      @click="deletePlace(place._id)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      devices: [],
    };
  },
  created() {
    var g = this;
    $.post(api + "/console/devices/list", {
      jwt: user.jwt,
    }).then(function (r) {
      g.devices = r.response;
    });
  },
  methods: {
    deletePlace(id) {
      var g = this;
      if (confirm("متأكد من الحذف؟")) {
        $.post(api + "/console/devices/delete", {
          jwt: user.jwt,
          id: id,
        }).then(function () {
          $.post(api + "/console/devices/list", {
            jwt: user.jwt,
          }).then(function (r) {
            g.devices = r.response;
          });
        });
      }
    },
  },
};
</script>

<style>
</style>